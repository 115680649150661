import React from "react";
import Layout from "../components/shared/layout";
import FormLayoutComponent from '../components/form-layout/form-layout.component';
import ContactInformationFormComponent from "../components/contact-info/contact-info-form.component";

const ContactInformationPage = () => {
  return (
    <Layout header={true} footer={true} isBackBtn={true}>
      <FormLayoutComponent route="contact-info">
        <ContactInformationFormComponent />
      </FormLayoutComponent>
    </Layout>
  )
}

export default ContactInformationPage

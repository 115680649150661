import translate from "../../i18n/translate"

export default function validate(values) {
  let errors = {}

  if (!values.customerFirstName.trim()) {
    errors.customerFirstName = translate(
      "contact.form.customerFirstName.errorText"
    )
  }

  if (!values.customerLastName.trim()) {
    errors.customerLastName = translate(
      "contact.form.customerLastName.errorText"
    )
  }

  if (!values.street1.trim()) {
    errors.street1 = translate("contact.form.street1.errorText")
  }

  if (!values.zipCode.trim()) {
    errors.zipCode = translate("contact.form.zipCode.errorText")
  }

  if (!values.city.trim()) {
    errors.city = translate("contact.form.city.errorText")
  }

  if (!values.userConsent) {
    errors.userConsent = translate("contact.form.userConsent.errorText")
  }

  // regex validation

  if (
    !values.customerEmail.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    )
  ) {
    errors.customerEmail = translate("contact.form.customerEmail.errorText")
  }

  if (
    values.customerSecondaryPhoneNo && values.customerSecondaryPhoneNo != "" && !values.customerSecondaryPhoneNo.match(/^[0]+\d{4,14}$|^(43)+\d{3,13}$/)
  ) {
    errors.customerSecondaryPhoneNo = translate("contact.form.customerSecondaryPhoneNo.errorText")
  }



  if (!values.zipCode.match(/^\d{4}$/)) {
    errors.zipCode = translate("contact.form.zipCode.errorText")
  }

  return errors
}

export function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object
}
